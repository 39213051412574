import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import utils from "@/utils";
import {ValidationObserver, ValidationProvider, extend} from "vee-validate";
import { digits, email, max, regex } from 'vee-validate/dist/rules'
// import 'material-design-icons-iconfont/dist/material-design-icons.css'

Vue.config.productionTip = false
Vue.filter('money', function (value) {
  if (typeof value !== "number") {
    return value;
  }
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'GHS'
  });
  return formatter.format(value);
});
Vue.filter('date', function (value) {
  const formatter = new Intl.DateTimeFormat('en-US', {
    weekday: 'short',
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });
  return formatter.format(value);
});
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
extend('digits', {
  ...digits,
  message: '{_field_} needs to be {length} digits. ({_value_})',
})

extend('required', {
  validate(value) {
    return {
      required: true,
      valid: ['', null, undefined].indexOf(value) === -1
    };
  },
  computesRequired: true,
  message: 'This field is required'
});

extend('max', {
  ...max,
  message: '{_field_} may not be greater than {length} characters',
})

extend('regex', {
  ...regex,
  message: '{_field_} {_value_} does not match {regex}',
})

extend('email', {
  ...email,
  message: 'Email must be valid',
})
extend('phoneNumber', {
  validate: (value) => {
    return value.match(/^0[0-9]{9}$/g);
  },
  message: 'Invalid phone number',
})
extend('numeric', {
  validate: (value) => {
    return !isNaN(value);
  },
  message: 'The value provided is not a numeric'
})
export const eventBus = new Vue();
Vue.mixin({
  methods: {
    ...utils
  }
})
new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
