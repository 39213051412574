import Vue from 'vue'
import VueRouter from 'vue-router'
import utils from "@/utils";
// import store from '@/store'
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: {name: 'Login'}
    },
    {
        name: 'Login',
        path: '/login',
        component: () => import('@/views/LoginView'),
    },
    {
        name: 'AssetViewer',
        path: '/asset-viewer',
        component: () => import('@/views/AssetViewer')
    },
    {
        name: 'App',
        path: '/app',
        component: () => import('@/views/app/AppView'),
        children: [
            {
                name: 'Companies',
                path: 'companies',
                component: () => import('@/views/app/CompanyView.vue'),
                meta: {
                    authenticationRequired: true,
                    permissions: ['COMPANY_READ']
                }
            },
            {
                name: 'Assets',
                path: 'assets',
                component: () => import('@/views/app/AssetView.vue'),
                meta: {
                    authenticationRequired: true,
                    permissions: ['ASSET_READ']
                }
            },
            {
                name: 'Users',
                path: 'users',
                component: () => import('@/views/app/UsersView'),
                meta: {
                    authenticationRequired: true,
                    permissions: ['USER_READ']
                }
            },
        ]
    }
]

const router = new VueRouter({
    routes
})

router.beforeEach((to, from, next) => {
    if (to.meta['authenticationRequired'] && !localStorage.getItem('accessToken'))
        next({name: 'Login', query: {next: to.path}});
    else if (to.meta['permissions'] && !utils.hasPermissions(to.meta['permissions']))
        next({name: 'Login'})
    else if (from.query['next'])
        next(from.query['next']);
    else
        next();
})

export default router
