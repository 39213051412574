<template>
	<v-app>
		<router-view/>
    <v-snackbar top color="error" class="justify-center" v-model="snackbars.error.visible" :timeout="10000"><v-icon>mdi-close-circle</v-icon>&nbsp;&nbsp;&nbsp;&nbsp;{{ snackbars.error.message }}</v-snackbar>
		<v-snackbar top color="info" class="justify-center" v-model="snackbars.info.visible" :timeout="10000"><v-icon>mdi-check-circle</v-icon>&nbsp;&nbsp;&nbsp;&nbsp;{{ snackbars.info.message }}</v-snackbar>
	</v-app>
</template>

<script>
import { eventBus } from "./main";
export default {
	name: 'App',
	data: () => ({
		snackbars: {
			error: {
				visible: false,
				message: ''
			},
      info: {
        visible: false,
        message: ''
      }
		}
	}),
	methods: {

	},
	mounted() {
		eventBus.$on('error', (message) => {
			this.snackbars.error.message = message;
			this.snackbars.error.visible = true;
		});
    eventBus.$on('info', (message) => {
      this.snackbars.info.message = message;
      this.snackbars.info.visible = true;
    })
	}
}
</script>
