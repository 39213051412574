import router from "./router";

export default {
    hasPermission(permission) {
        try {
            const accessToken = JSON.parse(localStorage.getItem('accessToken'));
            return !!accessToken.appUser.permissions.find(p => p === permission);
        } catch (e) {
            console.log(e);
            return false;
        }
    },
    hasPermissions(permissions) {
        for (const permission of permissions)
            if (!this.hasPermission(permission)) return false;
        return true;
    },
    isSuperuser() {
        try {
            const accessToken = JSON.parse(localStorage.getItem('accessToken'));
            return accessToken.appUser.role === 'SUPERUSER';
        } catch (e) {
            router.push({name: 'Login'});
        }
    },
    generateKey() {
        return `${(new Date()).getTime()}`;
    },
    fileToBase64(file) {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result.split(',')[1]);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    },
    getFileUrl(key) {
        return window.API_BASE_URL + '/files/' + key;
    },
    formatDate(date) {
        const formattedDate = date.toLocaleDateString('en-US', {
            day: '2-digit',
            month: 'short',
            year: 'numeric'
        });
        const formattedTime = date.toLocaleTimeString('en-US', {hour: '2-digit', minute: '2-digit', hour12: true});
        return `${formattedDate} ${formattedTime}`;
    }
}